import React from 'react'
import Layout from '../components/layout'

const Impressum = () => {
	return (
		<Layout title="Impressum">
			<div className="container-fluid pl-4">
				<div className="my-row mt-5">
					<h2>Impressum</h2>
				</div>
				<div className="my-row mt-4">
					<h4>
          betterHR GmbH
					</h4>
				</div>
				<div className="my-row mt-n1">
					<p>
        Helmholtzstraße 2-9<br/>
        10587 Berlin<br/>
        Deutschland<br/>
					</p>
				</div>
				<div className="my-row mt-3">
					<p>
          E-Mail: <a href="mailto:info@betterhr.de" rel="noopener noreferrer" target="_blank" className="text-dark font-weight-normal">info@betterhr.de</a>
						<br/>
          Telefon: (030) 232 570 999
					</p>
				</div>
				<div className="my-row mt-3">
					<p>
          Geschäftsführung: Dr. Jens Bäumer
						<br/>
          Registriert beim Amtsgericht Berlin Charlottenburg
					</p>
				</div>
				<div className="my-row mt-3">
					<p>
          Registriernummer: HRB186475B
						<br/>
          Umsatzsteuer-ID: DE312253266
					</p>
				</div>
				<div className="my-row mt-3">
					<p>
          VERANTWORTLICH FÜR DEN INHALT NACH § 55 ABS. 2 RSTV
						<br/>
          Dr. Jens Bäumer
					</p>
				</div>
				<div className="my-row mt-4">
					<h3 className="font-weight-light">
          Haftung für Inhalte
					</h3>
				</div>
				<div className="my-row mt-2">
					<p>
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
					</p>
				</div>
				<div className="my-row mt-4">
					<h3 className="font-weight-light">
          Urheberrecht
					</h3>
				</div>
				<div className="my-row mt-2">
					<p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen entfernen wir derartige Inhalte umgehend.
					</p>
				</div>
				<div className="my-row mt-2">
					<p>
					Hinweis nach Verordnung (EU) Nr. 524/2013, Plattform der EU zur außergerichtlichen Online-Streitbeilegung:
						<b> http://ec.europa.eu/consumers/odr/</b>				
					</p>
				</div>
				<div className="my-row mt-2">
					<p>
					Unsere Datenschutzerklärung finden Sie <a href="/datenschutz">hier</a>.
					</p>
				</div>
			</div>
		</Layout>
	)
}
 
export default Impressum